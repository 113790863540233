@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap');


* {
  font-family: "Roboto Mono", monospace;

  /* FONT OPTIONS */
  /*font-family: 'Poppins', sans-serif !important;*/
  /*font-family: "Josefin Sans", monospace;*/
  /*font-family: "Assistant", sans-serif;*/
  /*font-family: "Comfortaa", sans-serif;*/
  /*font-family: "Varela Round", sans-serif;*/
  /*font-family: "Tajawal", sans-serif;*/
  /*font-family: "Nanum Gothic Coding", monospace !important;*/

  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  /*background-color: #eeeeee;*/
  display: flex;
  justify-content: center;
  align-items: center;
}

.tiptap {
  background-color: #222222;
  padding: 40px;
}

.is-active {
  background-color: #aaaaaa;
}

.button-group-tiptap-menu button {
  margin: 2px;
  /*background-color: #eeeeee;*/
  border-radius: .5rem;
  border: none;
  font-size: .875rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.15;
  padding: .375rem .625rem;
}

#root {
  width: 100%;
}

p {
  color: #a3a3a3;
}

.App {
  text-align: center;
}

h1 {
  /*text-align: center;*/
  /*margin-bottom: 0.5rem;*/
  font-size: 5rem;
  font-weight: 700;
  font-family: 'Manrope', sans-serif;
}

h2 {
  /*color: #FFF;*/
  font-size: 1.5rem;
  font-weight: 300;
  font-family: 'Manrope', sans-serif;
}

#login-button {
  @media only screen and (max-width: 500px) {
    min-width: 150px;
    min-height: 40px;
  }
  min-width: 200px;
  min-height: 60px;
}

#signup-button {
  @media only screen and (max-width: 500px) {
    min-width: 150px;
    min-height: 40px;
  }

  min-width: 200px;
  min-height: 60px;
}

#savelyrics-button {
  @media only screen and (max-width: 500px) {
    min-width: 150px;
    min-height: 40px;
  }
  min-width: 200px;
  min-height: 60px;
}

#uploadfiles-button {
  min-width: 200px;
  min-height: 60px;
}

.song-priority-icon {
  width: 15px;
}

.album-table-view-icon {
	width: 100%;
	height: auto;
}

/*
  AUDIO PLAYER OVERRIDES
 */

.rhap_container {
  background-color: unset !important;
}

.rhap_rewind-button {
  display: none;
}

.rhap_forward-button {
  display: none;
}

.rhap_play-pause-button {
  margin-top: 10px !important;
  @media only screen and (min-width: 700px) {
    display: none;
  }
}

.rhap_controls-section {
  justify-content: center !important;
}

.rhap_progress-indicator {
  background-color: #FFFFFF !important;
}

.rhap_time {
  color: #525252 !important;
}

.rhap_main-controls-button {
  color: #525252 !important;
}

.moodboard-image {
  object-fit: cover;
}

.TodoWrapper {
  /*background: #F0F0F0;*/
  /*margin-top: 5rem;*/
  /*margin: auto;*/
  /*padding: 2rem;*/
  /*border-radius: 5px;*/
  /*width: 90%;*/
}

.songs-grid {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: space-between;
  grid-template-rows: auto auto auto;
  column-gap: 10px;
  row-gap: 15px;
}


.TodoForm {
  width: 350px;
}

.todo-input {
  outline: none;
  background: none;
  border: 5px solid #8758ff;
  padding: 0.5rem .5rem;
  /*margin-top: 1rem;*/
  /*margin-bottom: 2rem;*/
  width: 100%;
  color: #FFFFFF;
}

.todo-input::placeholder {
  color: #A4A4A4;
}

.todo-btn {
  background: #8758ff;
  color: #fff;
  border: 2px #fff;
  padding: 0.55rem;
  cursor: pointer;
}

.song-tile {
  border-radius: 10px;
  background-color: #444444;
  border: 2px solid #420076;
  box-shadow: 1px 2px 6px 0px #171bb8;
  margin: 1rem;
}

.song-notes-container {
  color: #FFF;
  padding: 2rem;
  background-color: #222;
}



.Todo {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: nowrap;
  flex-direction: column;
  color: #fff;
  padding: 2rem;
  border-radius: 5px;
}

.Song-Details {
  display: flex;
  justify-content: space-around;
  padding-top: .5rem
}

.Song-Audio-Player {
  display: flex;
}


.fa-trash {
  margin-left: 0.75rem;
}

.incompleted {
  color: #fff;
  cursor: pointer;
}

.completed {
  color: #c5aeff;
  text-decoration: line-through;
  cursor: pointer;
}

.edit-icon,
.delete-icon {
  cursor: pointer;
}
.Audio-Icon {
  cursor: pointer;
}


